import React, { Fragment, useEffect, useState } from 'react';
import FooterNote from '../component/Footer';
import MenuBar from '../component/Menu';
import client from '../component/client';
import Parser from 'html-react-parser';
import Spinner from 'react-bootstrap/Spinner';

const UserPrivacyPolicy = () =>{

  const [showLoader, setShowLoader] = useState(false);
  const [privacyData, setPrivacyData] = useState('');

  const privacyPolicyData = async() => {
      
    setShowLoader(true)
    //console.log("Sending...", sendData)
    try {
      const res = await client.get(`/api/fetchAboutCompany`)
      // eslint-disable-next-line eqeqeq
      if(res.data.msg =='200'){
        //console.log("data ", res.data.infoData.company_privacy_policy) 
        setPrivacyData(res.data.infoData.company_privacy_policy)
          }
        else if(res.data.status =='500'){
          console.log("error ", res.data)  
          }
        } catch (error) {
          console.log(error.message)
        }
        finally{
          setShowLoader(false)
          
        }
  }
    useEffect(() => {
      privacyPolicyData()
        function fadeout() {
            document.querySelector('.preloader').style.opacity = '0';
            document.querySelector('.preloader').style.display = 'none';
        }
        const timeoutID = window.setTimeout(fadeout,() => {
        }, 2000);
     
         return () => window.clearTimeout(timeoutID );
    }, [])

  return (
    <>
     <Fragment>
     <div className="preloader">
        <div className="preloader-inner">
          <div className="preloader-icon">
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
        <MenuBar />

        <div className="breadcrumbs">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 offset-lg-3 col-md-12 col-12">
                <div className="breadcrumbs-content">
                  <h1 className="page-title">Privacy Policy</h1>
                  <ul className="breadcrumb-nav">
                    <li>
                      <a href="/">Home</a>
                    </li>
                    <li>Company Privacy Policy</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="contact-us section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-12">
                <div className="contact-widget-wrapper">
                  <div className="main-title">
                  {/* <div className='loader'></div> */}
                  
                    <h2>Privacy Policy</h2>
                    
                   {showLoader ? 
                      <div className='d-flex justify-content-center'>
                        <Spinner
                          as="span"
                          animation="border"
                          size="large"
                          role="status"
                          border-color='#1D2667'
                          aria-hidden="true"
                         />
                      </div>:
                   <p style={{fontSize: 18, textAlign:'justify'}}>    
                    {Parser(privacyData)}
                   </p>
                    }

                    {/* <p style={{fontSize: 18, textAlign:'justify'}}>
                    Zictech Technologies Limited (“Oza”, “Oza platform”, “us”, “we”, or “our”) operates https://Oza.com (hereinafter referred to as “Service”, “Exchange”).<br />

Our Privacy Policy governs your visit to https://Oza.com, and explains how we collect, safeguard and disclose information that results from your use of our Service. We use your data to provide and improve our Service.
<br />
By using our Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, the terms used in this Privacy Policy have the same meanings as in our Terms of Use. Our Terms of Use (“Terms”) govern all use of our Service and together with the Privacy Policy constitutes your agreement with us (“Agreement”). We reserve the right to change any of our policies and practices at any time. You will always find the latest version of this Privacy Policy here on this page.
<br />
<strong>Definitions</strong><br />
<strong>SERVICE </strong> means the https://oza.com website operated by Zictech Technologies Limited.<br />
<br />
<strong>PERSONAL DATA </strong> means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).
<br />
<strong>USAGE DATA</strong> is data collected automatically either generated by the use of Service or from Service infrastructure itself (for example, the duration of a page visit).
<br />
<strong>COOKIES</strong> are small files stored on your device (computer or mobile device).
<br />
<strong>CLICKSTREAM INFORMATION</strong> is information collected about a user while they browse through a website, or use a browser.
<br />
<strong>DATA CONTROLLER</strong> means a natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal data are, or are to be, processed. For the purpose of this Privacy Policy, we are a Data Controller of your data.
<br />
<strong>DATA PROCESSORS (OR SERVICE PROVIDERS)</strong> means an individual, private entity, public authority, or any other body, who processes personal data on behalf of or at the direction of a data controller or another data processor.
<br />
<strong>DATA SUBJECT</strong> means an individual to whom personal data relates
<br />
<strong>THE USER</strong> is the individual using our Service. The User corresponds to the Data Subject, who is the subject of Personal Data.
<br />

<strong>1. Personal Information We Collect</strong>
Personal information typically means information that identifies or is reasonably capable of identifying an individual, directly or indirectly, and information that relates to, describes, is reasonably capable of being associated with or could reasonably be linked to an identified or reasonably identifiable individual. For the purposes of this Privacy Policy, only the definition of personal information from the applicable law of your legal residence will apply to you and be deemed your “Personal Information.”
<br />
<strong>A. Personal Information we collect from you</strong><br />
We may collect the following categories of Personal Information directly from you:
<br />
<strong>Identification Information,</strong> such as name, email, phone number, postal address, and/or government-issued identity documents;
<br/>
<strong>Commercial Information,</strong> such as trading activity, buying, selling, order activity, deposits, withdrawals, account balances;
<br/>
<strong>Financial Information,</strong> such as bank account information, routing number;
<br/>
<strong>Correspondence,</strong> such as information that you provide to us in correspondence, including account opening and customer support;
<br/>
<strong>Audio, Electronic, Visual, Thermal, Olfactory, or Similar Information,</strong>
  such as images and videos collected for identity verification, audio recordings left via messaging;
<br/>
<strong>Biometric Information, </strong> such as scans of your face geometry extracted from identity documents;
<br/>
<strong>Professional or Employment-related Information,</strong> such as job title, source of wealth; and
<br/>
<strong>Institutional Information,</strong> such as for institutional customers, we may collect additional information, including: institution’s legal name, identification number issued by a government, and proof of legal existence (which may include memorandum and articles of incorporation, certificate of incorporation, business license, trust instrument, or other comparable legal document).
<br/>
<strong>Sensitive Personal Information,</strong> such as government-issued identification numbers (which may include Social Security Number or equivalent, driver’s license number, passport number).
<br/>
<strong>B. Personal Information we collect automatically</strong><br/>
We may collect the following categories of Personal Information automatically through your use of our services:

Online Identifiers, such as IP address; domain name;<br/>
Device Information, such as hardware, operating system, browser, screen size;
<br/>
Usage Data, such as system activity, internal and external information related to Oza pages that you visit, and clickstream information.
<br/><br/>
Our automatic collection of Personal Information may involve the use of Cookies, described in greater detail below.

<strong>C. Personal Information we collect from third parties</strong><br/>
We may collect and/or verify the following categories of Personal Information about you from Third Parties:
<br/>
Identification Information, such as name, email, phone number, and postal address.
Financial Information, such as bank account information, and routing number;
Transaction Information, such as public blockchain data (bitcoin, ether, and other Digital assets are not truly anonymous;
We, and any others who can match your public Digital Asset address to other Personal Information about you, may be able to identify you from a blockchain transaction because, in some circumstances, Personal Information published on a blockchain (such as your Digital Funds address and IP address) can be correlated with Personal Information that we and others may have. Furthermore, by using data analysis techniques on a given blockchain, it may be possible to identify other Personal Information about you;
Credit and Fraud Information, such as credit investigation, credit eligibility, identity or account verification, fraud detection, or as may otherwise be required by applicable law;
Sensitive Personal Information, such as government identification numbers (which may include National Identification Number (NIN)/Bank Verification Number (BVN)/Social Security Number or equivalent, driver’s license number, passport number); and
Additional Information, as permitted by law or required to comply with legal obligations, which may include criminal records or alleged criminal activity, or information about any person or corporation with whom you have had, currently have, or may have a financial relationship.
Personal Information you provide during the registration process may be retained, even if your registration is left incomplete or abandoned.

2. How We Use Your Personal Information
The Personal Information we collect and the practices described above are done in an effort to provide you with the best experience possible, protect you from risks related to improper use and fraud, and help us maintain and improve our Service. We may use your Personal Information to:

A. Provide you with our services
We use your Personal Information to provide you with our services and maintain these services pursuant to the terms of our Terms of Use. For example, in order to facilitate fiat transfers into and out of your account, we need to know your financial information. We also use your Personal Information to carry out our obligations and enforce our rights arising from any contracts entered into between you and us, including for billing and collection.

B. Comply with legal and regulatory requirements
example, we have identity verification requirements to fulfill our obligations under the anti-money laundering laws of numerous jurisdictions and, as such, we may use an image of an identity document that you provide to help us verify that you are the true owner of the identity document and that the document does not show signs of fraud.

C. Detect and prevent fraud
We process your Personal Information to detect and prevent fraud on your account, which is especially important given the irreversible nature of cryptocurrency transactions.

D. Protect the security and integrity of our services
We use your Personal Information to further our security-first mentality. Maintaining the security of your account and the Exchange requires us to process your Personal Information, including information about your device and your activity on the Exchange, and other relevant information.

E. Provide you with customer support
We process your Personal Information anytime that you reach out to our Customer Support team, such as when there are issues arising from your account.

F. Optimize and enhance our services
We use your Personal Information to understand how our products and Services are being used to help us improve our Services and develop new products, and interactive features.

G. Market our products to you
We may contact you with information about our products and services that we believe may be of interest to you. You may use tools that we provide to opt-out of marketing communications from us at any time.

H. With your consent
We may use your Personal Information for additional purposes with your consent.

I. Other business purposes
We may use your Personal Information for additional purposes in the operation of our business, that would be reasonably expected based on context, and as permitted by law or required to comply with our legal obligations.

3. How We Share Your Personal Information
We will not share your Personal Information with third parties, except as described below:

A. Service Providers
We may share your Personal Information with third-party service providers for business or commercial purposes. Your Personal Information may be shared so that they can provide us with services, including identity verification, fraud detection and prevention, credit verification, security threat detection, payment processing, customer support, data analytics, Information Technology, advertising, marketing, data processing, network infrastructure, storage, transaction monitoring, and tax reporting. We share your Personal Information with these service providers only so that they can provide us with services, and we prohibit our service providers from using or disclosing your Personal Information for any other purpose. Our third-party service providers are subject to strict confidentiality obligations.

B. Affiliates
We may share your Personal Information with our affiliates for the purposes outlined above, and as it is necessary to provide you with our Services.

C. Legal Obligations
We may be required or in good faith and in our sole discretion believe it is necessary to share your Personal Information with law enforcement, government agencies, regulators or third parties to comply with legal or regulatory requirements, or to preserve our rights or to protect against fraud or other illegal activity.

D. Corporate Transactions
We may disclose Personal Information in the event of a proposed or consummated merger, acquisition, reorganization, asset sale, or similar corporate transaction, or in the event of a bankruptcy or dissolution.

E. Professional Advisors
We may share your Personal Information with our professional advisors, including legal, accounting, or other consulting services for purposes of audits or to comply with our legal obligations.

F. Consent
We may share or disclose your information with your consent.

G. Other Business Purposes
We may share or disclose your Personal Information for other business purposes as permitted by law.

If we decide to modify the purposes for which your Personal Information is collected, used, or shared, or our practices relating to your Personal Information, we will amend this Privacy Policy as described below.

4. Cookies
When you access our Service, we may make use of the standard practice of placing tiny data files called cookies, flash cookies, pixel tags, or other tracking tools (herein, “Cookies”) on your computer or other devices used to visit Oza. We use Cookies to help us recognize you as a customer, collect information about your use of Oza to better customize our services and content for you, and collect information about your computer or other access devices to:

Ensure compliance with the Nigerian Data Protection Act (“NDPA”) 2023, Nigerian Data Protection Regulation (“NDPR,”) 2019, and the European General Data Protection Regulation (“GDPR”) 2018;
Ensure that your account security has not been compromised by detecting irregular, suspicious, or potentially fraudulent account activities; and
Assess and improve our services and advertising campaigns.
We use the following types of cookies on our online channels, such as our website:

Strictly necessary cookies:
These cookies are mandatory and are required for the effective operation and functioning of our website on your device. They enable you to use the website and the features on the website and cannot be switched off.
Performance cookies:
These are optional cookies that collect information about how you use the website but not any personal information. Performance information is anonymous and mostly statistical and is used to improve the performance of our website.
Marketing cookies or advertising cookies:
These cookies are also optional and are used to deliver and display advertisements that are relevant and engaging for you as the user. They help us measure how effective our advertising campaigns are by your interaction with the advertisement.
Session cookies:
These cookies are temporary and optional and only exist while you browse our website to remember your activities on the website. As soon as you close the website or move to a different website, the cookies are deleted.
Persistent cookies:
These are permanent, optional cookies that are stored on your device until they reach a set expiry date or until you delete them. They remember your preferences or actions on our website (or in some cases across different websites). We may use them for various reasons, for example to remember your preferences and choices when you use our website, or to display relevant advertising campaigns to you.
First-party cookies:
These are cookies that we create and store when you use our website and relate to information obtained directly from you.
We may make use of third-party Cookies or analytics services, including but not limited to Google Analytics, and Facebook.

To learn more about Google Analytics and how to opt-out of making your activities available to Google Analytics, please visit Google Marketing Platform or Google Analytics Help.

To learn more about how Facebook uses your data, please visit Facebook Help Center or log on to your Facebook account and access your settings. To understand more about Facebook advertising, please see Facebook Ads.

Third-party tracking technologies are not controlled by us, and statements regarding our practices do not apply to these third-parties or their use of information. We make no representations regarding the policies or practices of such third parties. We are not responsible for the effectiveness of or compliance with any third-parties’ opt-out options.

You can also learn more about cookies by visiting All About Cookies, which includes additional useful information on cookies and how to block cookies on different types of browsers and mobile devices.

5. Direct Marketing
Subject to applicable laws and regulations, we may from time to time send direct marketing materials promoting services, products, facilities, or activities to you using information collected from or about you. You may opt-out of such marketing communications at any time by visiting the privacy tab of your Account page. You may also opt-out of such communications by following the directions provided in any marketing communication. It is our policy to not provide your Personal Information to third parties for their own direct marketing purposes without your consent. If you opt-out of marketing communications, please note that we may still send you necessary information about transactions or our business relationship.

6. Children’s Personal Information
Oza is not intended for children under the age of 18, and we do not knowingly collect Personal Information of children under the age of 18. If we learn that we have collected any Personal Information from a child under the age of 18, we will promptly delete it from our systems.

7. Information Security
We take reasonable measures, including administrative, technical, and physical safeguards, to protect your Personal Information from loss, theft, or misuse, and from unauthorized access, disclosure, alteration, and destruction. Nevertheless, no security is foolproof, and the Internet is an insecure medium. We cannot guarantee absolute security, but we work hard to protect Oza and you from unauthorized access to or unauthorized alteration, disclosure, or destruction of Personal Information we collect and store. Measures we take may include encryption of the Oza website communications with SSL; required two-factor authentication for all sessions; periodic review of our Personal Information collection, storage, and processing practices; and restricted access to your Personal Information on a need-to-know basis for our employees, contractors, and agents who are subject to strict contractual confidentiality obligations and may be disciplined or terminated if they fail to meet these obligations.

8. Retention
We retain your Personal Information for as long as is reasonably necessary to provide services to you, for our legitimate business purposes, and to comply with our legal and regulatory obligations. If you close your account with us, we will continue to retain your Personal Information as necessary to comply with our legal and regulatory obligations, including for fraud monitoring, detection and prevention; and for our tax, accounting, and financial reporting obligations.

9. International Transfers
Oza aims to be a global business. As a result, Personal Information may be stored and processed in any country where we have operations or where we engage service providers. We may transfer Personal Information that we maintain about you to recipients in countries other than the country in which the Personal Information was originally collected. Those other countries may have data protection or privacy rules that are different from those in Nigeria. However, we will take measures to ensure that any such transfers comply with applicable data protection laws and that your Personal Information remains protected to the standards described in this Privacy Policy. In certain circumstances, courts, law enforcement agencies, regulatory agencies, or security authorities in those other countries may be entitled to access your Personal Information.

If you are located in the European Economic Area (“EEA”), the UK, or Switzerland, we comply with applicable GDPR law to provide an adequate level of data protection for the transfer of your Personal Information to the US or other third countries. Oza enters into the appropriate data processing agreements and, if required, standard contractual clauses for the transfer of data which have been approved by the applicable data protection regulatory authority.

10. What are your Rights?
We value your trust and want you to be familiar with your rights under the NDPA and to know how you can exercise them in your interactions with Oza. You have the right to:

Request Access:
You may request access to the personal information we hold about you, and to correct and update your information. You may submit a request that Oza disclose the categories of Personal Information we have collected about you, the categories of sources from which your Personal Information is collected, the business or commercial purpose for collecting, selling, or sharing your Personal Information, the categories of third parties with whom we disclose your Personal Information, and the specific pieces of Personal Information we have collected about you.
Information:
You may request information that we maintain about you, subject to appropriate verification.
Request Erasure/Deletion:
You may submit a request that Oza delete the personal information about you that we have collected from you. Note that there are some reasons we will not be able to fully address your request, such as if we need to complete a transaction for you, to detect and protect against fraudulent and illegal activity, to exercise our rights, for our internal purposes, or to comply with a legal obligation.
Rectification:
You may submit a request that Oza correct inaccurate Personal information we have collected and maintain about you.
Data Portability:
You have the right to receive the personal data you have provided to us in an electronic format and to transmit that Personal Information to another data controller.
Object:
You may object to our processing your personal information, where applicable.
Be notified that your personal information is being collected by us or has been accessed or acquired by an unauthorized person.
Knowledge of the existence of automated decision-making processes in the application for products and/or services, except under certain circumstances.
To request reasons or make a representation to us if your application for products and/or services is refused.
How to Submit a Request:
You may submit a request to access, delete, or correct your Personal Information by emailing us at privacy@Oza.com. We are legally obligated to verify your identity when you submit a request.

We may request additional information from you, like your date of birth or a personal identification number, to verify your identity. Oza will confirm receipt of your request within 10 business days and will respond to your request within 45 calendar days, after proper verification, unless we need additional time, in which case we will let you know.

If we are unable to verify your identity, we may deny your request. If you submit a request to delete your Personal Information, you will also be required to confirm the deletion request by email.

Please understand that we are not obligated to provide you with access to your Personal Information more than twice in a 12-month period.

If your request is manifestly unfounded or excessive, we may either charge a reasonable fee or refuse to act on the request, in which case we will notify you of the reason for refusing the request.

11. Information for Persons Subject to UK Data Protection Laws
If you are located in the UK and are provided services, we adhere to relevant UK data protection laws and provide individuals with the following additional information. For purposes of this section, “personal data” has the meaning provided in the General Data Protection Regulation (EU) 2016/679 as it forms part of the law of England and Wales, Scotland, and Northern Ireland by virtue of section 3 of the European Union (Withdrawal) Act 2018(“GDPR”).

Lawful bases for processing

Legal Obligation:
to conduct anti-fraud and identity verification and authentication checks and to fulfill our retention obligations;
Contractual Obligation:
to satisfy our obligations to you under our Terms, including to provide you with our services and customer support services, and to optimize and enhance Oza;
Legitimate Interest:
to monitor the usage of Oza, conduct automated and manual security checks of our service, to protect our rights; and
Consent:
to market Oza and our services to you. You may withdraw your consent at any time without affecting the lawfulness of processing based on consent before consent is withdrawn.
European privacy rights

Rights to Access and Rectification:
You may submit a request that Oza disclose the personal data that we process about you and correct any inaccurate personal data.
Right to Erasure:
You may submit a request that Oza delete the personal data that we have about you.
Right to Restriction of Processing and Right to Object:
You have the right to restrict or object to our processing of your personal data under certain circumstances.
Right to Data Portability:
You have the right to receive the personal data you have provided to us in an electronic format and to transmit that Personal Information to another data controller.
When handling requests to exercise your privacy rights, we check the identity of the requesting party to ensure that he or she is the person legally entitled to make such request. While we maintain a policy to respond to these requests free of charge, should your request be repetitive or unduly onerous, we reserve the right to charge you a reasonable fee for compliance with your request.

Automated decision-making

We may engage in automated decision-making for purposes of fraud detection and prevention. When we do, we implement suitable measures to safeguard your rights and freedoms and legitimate interests, including the right to obtain human intervention, to express your point of view and to contest the decision.

12. Updates To this Privacy Policy
We may change this Privacy Policy from time to time to reflect changes to our privacy practices, enhance user experience, or comply with relevant laws. The “Last updated” legend at the top of this Privacy Policy indicates when this Privacy Policy was last revised. Any changes are effective when we post the revised Privacy Policy. Your continued use of Oza after our posting of changes to this Privacy Policy means that you understand and agree to such changes.

If you are located in Nigeria and you believe that we have not adequately resolved any such issues, you have the right to contact the Nigerian Data Protection Commission here.

                    </p> */}
                  </div>
                  
                </div>
              </div>
              
            </div>
          </div>
        </div>

        <FooterNote/>
      </Fragment>
    </>
  );
}

export default UserPrivacyPolicy;


